import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import AOS from "aos";
import { useLocation } from "react-router-dom";
import "aos/dist/aos.css";
import Sidebar from "./Sidebar";
import { IoMdMenu } from "react-icons/io";

AOS.init({ once: true });

const AdminLayout = ({ children }) => {
  const location = useLocation();

  const [isMenuDropdownOpen, setMenuDropdownOpen] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="flex h-screen lg:flex-row flex-col">
      <div className=" lg:hidden block text-4xl" onClick={() => setMenuDropdownOpen(!isMenuDropdownOpen) } > <IoMdMenu className=" text-blue-500" /> </div>
      {/* Sidebar with fixed width */}
      { isMenuDropdownOpen && 
      <Sidebar className="w-60 bg-gray-800 text-white fixed left-0 top-0" />
      }

      {/* Main content area */}
      <div className="flex-1 flex flex-col relative">
        {/* Header at the top */}
        { isMenuDropdownOpen && 
        <Header className="bg-gray-700 text-white p-4" />
}

        {/* Main content (children) below the header */}
        <main className="flex-grow p-6 bg-[#EEF5FF]">
          {children}
        </main>
      </div>
    </div>
  );
};

AdminLayout.propTypes = {
  children: PropTypes.node,
};

export default AdminLayout;
