/* Route declarations for the app */

import * as reviews from "../views";
import Home from "../views/Home";
import HomeLayout from "../Layout/HomeLayout/homeLayout";
import WebinarPage from "../views/Webinar/webinar";
import Courses from "../views/Courses";
import Internship from "../views/Internship/layout";
import Taships from "../views/Internship/Taships";
import Eligible from "../views/Internship/Eligible";
import Applied from "../views/Internship/Applied";
import courseContent from "../views/CourseContent/courseContent";
import mycourse from "../views/MyCourse/mycourse";
import DashboardAndDoubts from "../views/Dashboard/dashboard";
import CourseDetails from "../views/courseDetails/courseDetail";
import JobDesc from "../views/JobDescription";
import UserDetail from "../views/UserDetail/UserDetail";
import Admin from "../views/Admin/Admin";
import InstructorDetail from "../component/Admin/InstructorDetail";
import AdminLayout from "../Layout/AdminLayout/adminLayout";
import AdminDashboard from "../component/Admin";
import Adminn from "../component/Admin/layout";
import InstructorTab from "../component/Admin/Instructor";
import PendingDetails from "../component/Admin/PendingDetails";
import CourseList from "../component/Admin/CourseList";
import CourseCategory from "../component/Admin/CourseTab";
import EnrollHistory from "../component/Admin/EnrollmentTab";
import EnrollInstructor from "../component/Admin/EnrollInstructor";
import StudentTab from "../component/Admin/StudentTab";
import TAList from "../component/Admin/TAList";
import TAProgress from "../component/Admin/TAProgress";
import TADetails from "../component/Admin/TADetails";
import Instructor from "../component/Instructor/layout";
import InstructorLayout from "../Layout/InstructorLayout/instructorLayout";
import InstructorDashboard from "../component/Instructor/Dashboard";
import InstructorCourses from "../component/Instructor/Courses";
import Student from "../component/Instructor/Student";
import AddCourse from "../component/Instructor/AddCourse";
import AddLesson from "../component/Instructor/AddLesson";
import EditComponent from "../component/Instructor/EditComponent";
import EnrollmentDetails from "../component/Admin/EnrollmentDetails";
import InstructorProfile from "../component/Instructor/InstructorProfile";
import AboutUs from "../views/AboutUs";
const routData = [
  // {
  //     id:"0",
  //     path: '/',
  //     component: Home,
  //     // guard: true,
  //     exact: true,
  //     layout :HomeLayout
  // },
  // {
  //     id:"1",
  //     path: '/login',
  //     component: reviews.Login,
  //     exact: true,
  // },
  // {
  //     id:"2",
  //     path: '/register',
  //     component: reviews.Register,
  //     exact: true,
  // },
  // {
  //     id:"3",
  //     path: '/webinar',
  //     component: WebinarPage,
  //     exact: true,
  //     layout :HomeLayout
  // },
  // {
  //     id:"4",
  //     path: '/courses',
  //     component: Courses,
  //     exact: true,
  //     layout :HomeLayout
  // },

  // {
  //     id: "5",
  //     path: '/internship',
  //     component: Internship,
  //     exact: true,
  //     layout: HomeLayout,

  // },
  {
    name: "Home",
    path: "/",
    component: Home,
    exact: true,
    layout: HomeLayout,
  },
  {
    name: "Login",
    path: "/login",
    component: reviews.Login,
    exact: true,
  },

  {
    name: "Register",
    path: "/signup",
    component: reviews.Register,
    exact: true,
  },

  {
    name: "Courses",
    path: "/courses",
    component: Courses,
    exact: true,
    layout: HomeLayout,
  },
  {
    name: "Course Detail",
    path: "/course-details/:id",
    component: CourseDetails,
    exact: true,
    layout: HomeLayout,
  },
  {
    name: "About Us",
    path: "/about-us",
    component: AboutUs,
    exact: true,
    layout: HomeLayout,
  },

  {
    name: "Webinar",
    path: "/webinar",
    component: WebinarPage,
    exact: true,
    layout: HomeLayout,
  },
  {
    name: "CourseContent",
    path: "/course-content/:id",
    component: courseContent,
    exact: true,
    layout: HomeLayout,
  },
  {
    name: "MyCourse",
    path: "/my-course",
    component: mycourse,
    exact: true,
    layout: HomeLayout,
  },
  {
    name: "Dashboard",
    path: "/dashboard",
    component: DashboardAndDoubts,
    exact: true,
  },
  {
    name: "UserDetail",
    path: "/user-detail",
    component: UserDetail,
    exact: true,
    layout: HomeLayout,
  },
  // {
  //   name: "Admin",
  //   path: "/admin",
  //   component: Admin,
  //   exact: true,
  // },

  {
    name: "Internship",
    path: "/internship/*",
    component: Internship,
    layout: HomeLayout, 
    routes: [
      {
        name: "taship",
        path: "", // Relative path to the parent route
        component: Taships,
        layout: Internship, // Applies a dashboard-specific layout
      },
      {
        name: "eligible",
        path: "eligible", // Relative path to the parent route
        component: Eligible,
        layout: Internship, // Applies a dashboard-specific layout
      },
      {
        name: "Applied",
        path: "applied",
        component: Applied,
        layout: Internship,
      },
    ],
  },
  {
    name: "Admin",
    path: "/admin/*",
    component: Adminn,
    layout: AdminLayout, 
    routes: [
      {
        name: "dashboard",
        path: "dashboard", 
        component: AdminDashboard,
      },
      {
        name: "instructor",
        path: "instructor", 
        component: InstructorTab,
      },
      {
        name: "instructorDetails",
        path: "instructor-details/:id", 
        component: InstructorDetail,
      },
      {
        name: "pendingRequests",
        path: "instructor-pending-requests",
        component: PendingDetails,
      },
      {
        name: "category",
        path: "courses-category", 
        component: CourseCategory,
      },
      {
        name: "courseList",
        path: "courses-list", 
        component: CourseList,
      },
      {
        name: "enrollHistory",
        path: "enroll-history", 
        component: EnrollHistory,
      },
      {
        name: "enrollDetail",
        path: "enroll-details/:id", 
        component: EnrollmentDetails,
      },
      // {
      //   name: "enrollInstructor",
      //   path: "enroll-instructor", 
      //   component: EnrollInstructor,
      // },
      // {
      //   name: "student",
      //   path: "student", 
      //   component: StudentTab,
      // },
      {
        name: "taList",
        path: "ta-list", 
        component: TAList,
      },
      {
        name: "taProgress",
        path: "ta-progress", 
        component: TAProgress,
      },
      {
        name: "taDetail",
        path: "ta-detail", 
        component: TADetails,
      },
    ],
  },

  {
    name: "Instructor",
    path: "/instructor/*",
    component: Instructor,
    layout: InstructorLayout, 
    routes: [
      {
        name: "dashboard",
        path: "dashboard", 
        component: InstructorDashboard,
      },
      {
        name: "courses",
        path: "courses", 
        component: InstructorCourses,
      },
      {
        name: "student",
        path: "student", 
        component: Student,
      },
      {
        name: "addCourse",
        path: "/courses/add-course", 
        component: AddCourse,
      },
      {
        name: "addLesson",
        path: "/courses/add-lesson/:id", 
        component: AddLesson,
      },
      {
        name: "editCourse",
        path: "/courses/edit-course/:id", 
        component: EditComponent,
      },
      {
        name: "instructorProfile",
        path: "instructor-profile", 
        component: InstructorProfile,
      },
    ],
  },
  {
    name: "Job Description",
    path: "/job-description",
    component: JobDesc,
    exact: true,
    layout: HomeLayout,
  },
];

export default routData;
