import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import AOS from "aos";
import { useLocation } from "react-router-dom";
import "aos/dist/aos.css";
import Sidebar from "./Sidebar";

AOS.init({ once: true });

const InstructorLayout = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="flex h-screen lg:flex-row flex-col">
      {/* Sidebar with fixed width */}
      <Sidebar className="w-60 bg-gray-800 text-white fixed left-0 top-0" />

      {/* Main content area */}
      <div className="flex-1 flex flex-col relative">
        {/* Header at the top */}
        <Header className="bg-gray-700 text-white p-4" />

        {/* Main content (children) below the header */}
        <main className="flex-grow p-6 bg-[#EEF5FF]">
          {children}
        </main>
      </div>
    </div>
  );
};

InstructorLayout.propTypes = {
  children: PropTypes.node,
};

export default InstructorLayout;
