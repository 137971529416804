import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const data = [
    {
        img: "/home-banner/team/garima.png",
        name: "Garima Singh",
        position: "CEO"
    },
    {
        img: "/home-banner/team/zaman.png",
        name: "Zaman Akbar Zaidi",
        position: "Blockchain Course Coordinator"
    },
    {
        img: "/home-banner/team/himanshu.png",
        name: "Himanshu Chaurasia",
        position: " AI and cybersecurity course coordinator"
    },
    {
        img: "/home-banner/mansi.png",
        name: "Mansi Naik",
        position: "Manager"
    },
    {
        img: "/home-banner/mansi.png",
        name: "Mansi Naik",
        position: "Manager"
    },
    {
        img: "/home-banner/mansi.png",
        name: "Mansi Naik",
        position: "Manager"
    },
]

function Team() {
  return (
    <div className="w-full bg-white  pl-8 pr-8 pt-10  lg:pt-20">
      <div className="flex flex-col">
        <h2
          className="text-black text-2xl md:text-4xl lg:text-4xl tracking-wide"
          style={{ borderLeft: "5px solid #0b5397", paddingLeft: "20px" }}
        >
          Our Team
        </h2>
        <p className="mt-4 lg:mt-10 text-black text-left text-xl lg:text-2xl lg:tracking-wide">
          The team behind Chain Edge is comprised of experienced professionals
          with diverse backgrounds in blockchain, AI, education, and technology.
          From seasoned industry experts to passionate educators, each member of
          our team brings unique insights and expertise to the table. Together,
          we are united by our shared vision and commitment to providing
          learners with the best possible educational experience.
        </p>
      </div>
      <div className="w-full h-full mt-4 lg:mt-10">
        <Swiper
        
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
          spaceBetween={30}
          loop={true}
          pagination={{
            dynamicBullets: true,
            clickable: true,
          }}
          navigation={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Pagination, Navigation, Autoplay]}
          className="mySwiper"
        >
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                className="w-full h-full relative sm:p-2 md:p-3 lg:p-4"
                key={index}
              >
                <div
                  className="w-full h-full relative p-4"
                  style={{ boxShadow: "0px 4px 100px 0px #eff5f9" }}
                >
                  <img src={item.img} alt={item.name} />
                  <p
                    style={{ marginTop: "5px" }}
                    className="text-black text-center text-xl lg:text-2xl lg:tracking-wide"
                  >
                    {item.name}
                  </p>
                  
                    <p className="my-4 text-center text-black text-xl lg:text-2xl lg:tracking-wide">
                      {item.position}
                    </p>
              
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Team